<template>
  <div>
    <div class="row mb-2 mt-n4 no-gutters mx-n1">
      <div class="col-md-8 px-2">
        <div class="card">
          <div class="row p-2">
            <!-- Filter Category -->
            <div class="col-md-8">
              <b-nav pills class="nav-category pb-2">
                <b-nav-item
                  :active="navActive == null"
                  @click="
                    () => {
                      navActive = null;
                      filter.medicine_category_id = '';
                      getMedicine();
                    }
                  "
                >
                  Semua
                </b-nav-item>
                <b-nav-item
                  v-for="(category, index) in medicineCategories"
                  :key="category.id"
                  :active="navActive == index"
                  @click="navOnClick(index, category.id)"
                >
                  {{ category.name }}
                </b-nav-item>
              </b-nav>
            </div>

            <!-- Search By Name -->
            <div class="col-md-4">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text squared>
                    <b-icon-search></b-icon-search>
                  </b-input-group-text>
                </template>
                <b-form-input
                  type="text"
                  v-model="filter.search"
                  placeholder="Cari Obat"
                  @keyup="inputSearchOnKeyUp"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 px-2">
        <div class="row no-gutters mx-n1">
          <div class="col-md px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info text-center"
              style="height: 54.23px; font-size: 13px"
              v-b-modal.modal-patient
            >
              <div class="text-truncate" style="font-size: 13px; width: 100px">
                {{ display.patient_name }}
              </div>
            </b-button>
          </div>
          <div class="col-md px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info text-center"
              style="height: 54.23px; font-size: 13px"
              v-b-modal.modal-doctor
            >
              <div class="text-truncate" style="font-size: 13px; width: 100px">
                {{ display.doctor_name }}
              </div>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters mx-n1">
      <div class="col-md-8 px-2" style="overflow-y: auto">
        <div class="row no-gutters mx-n1" style="height: calc(100vh - 400px)">
          <div
            v-for="medicine in medicines"
            :key="medicine.id"
            class="col-md-3 p-2"
          >
            <MedicineCard :data="medicine" @cardOnClick="cardMedicineOnClick" />
          </div>
        </div>
      </div>

      <div class="col-md px-2">
        <div class="row no-gutters my-1 mx-n1">
          <div class="col-md px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info"
              style="height: 54.23px; font-size: 13px"
              @click="$bvModal.show('modal-prescription')"
              >Pilih Resep</b-button
            >
          </div>
        </div>
        <div class="row no-gutters my-1 mx-n1">
          <div class="col-md">
            <div
              class="card shadow-sm mb-2"
              style="min-height: calc(100vh - 290px)"
            >
              <div
                class="card-body py-2 px-3"
                style="height: 400px; overflow-y: auto"
              >
                <!-- items unavailable stock -->
                <div
                  class="list-group list-group-flush"
                  style="overflow: hidden"
                >
                  <div
                    class="row py-1"
                    v-for="(item, index) in form.itemsUnavailable"
                    :key="item.medicine_id"
                  >
                    <div
                      class="
                        col-md-12
                        d-flex
                        justify-content-between
                        align-items-center
                        pb-0
                      "
                    >
                      <div
                        class="text-truncate"
                        style="font-size: 13px; width: 100px"
                      >
                        {{ item.medicine_name }}
                      </div>
                      <div class="btn-group btn-group-sm">
                        <b-button
                          squared
                          class="btn btn-sm btn-info square"
                          @click="
                            btnMinQtyOnClick(
                              item.medicine_id,
                              item.quantity,
                              item.amount,
                              index
                            )
                          "
                          ><i class="fas fa-sm fa-fw fa-minus"></i
                        ></b-button>
                        <span
                          ><button class="btn btn-sm btn-block bg-white">
                            {{ item.quantity }}
                          </button></span
                        >
                        <b-button
                          squared
                          class="btn btn-sm btn-info"
                          @click="
                            btnAddQtyOnClick(
                              item.medicine_id,
                              item.quantity,
                              item.amount,
                              index
                            )
                          "
                          ><i class="fas fa-sm fa-fw fa-plus"></i
                        ></b-button>
                      </div>
                      <div class="text-right">
                        {{
                          parseInt(
                            parseInt(item.amount) * parseInt(item.quantity)
                          ).toLocaleString("id-ID")
                        }}
                      </div>
                    </div>
                    <div class="col-md-12 pt-1 pb-2">
                      <small class="text-danger"
                        >Sisa stok {{ item.medicine_name }} yang tersedia tidak
                        mencukupi</small
                      >
                    </div>
                  </div>
                </div>

                <!-- items available stock -->
                <div class="list-group list-group-flush">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      py-2
                    "
                    v-for="(item, index) in form.items"
                    :key="item.medicine_id"
                  >
                    <div
                      class="text-truncate"
                      style="font-size: 13px; width: 100px"
                    >
                      {{ item.medicine_name }}
                    </div>
                    <div class="btn-group btn-group-sm">
                      <b-button
                        squared
                        class="btn btn-sm btn-info square"
                        @click="
                          btnMinQtyOnClick(
                            item.medicine_id,
                            item.quantity,
                            item.amount,
                            index
                          )
                        "
                        ><i class="fas fa-sm fa-fw fa-minus"></i
                      ></b-button>
                      <span
                        ><button class="btn btn-sm btn-block bg-white">
                          {{ item.quantity }}
                        </button></span
                      >
                      <b-button
                        squared
                        class="btn btn-sm btn-info"
                        @click="
                          btnAddQtyOnClick(
                            item.medicine_id,
                            item.quantity,
                            item.amount,
                            index
                          )
                        "
                        ><i class="fas fa-sm fa-fw fa-plus"></i
                      ></b-button>
                    </div>
                    <div class="text-right">
                      {{
                        parseInt(
                          parseInt(item.amount) * parseInt(item.quantity)
                        ).toLocaleString("id-ID")
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer py-2 px-3 bg-white">
                <div class="d-flex justify-content-between">
                  <div>Subtotal</div>
                  <div class="font-weight-bold">
                    {{ parseInt(form.total_amount).toLocaleString("id-ID") }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>Total</div>
                  <div class="font-weight-bold">
                    {{ parseInt(form.final_amount).toLocaleString("id-ID") }}
                  </div>
                </div>
                <div class="d-flex">
                  <small class="text-danger" v-if="form.unavailable != []"
                    >*Hanya item dengan stok tersedia yang dapat dibayar</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters mx-n1">
      <div class="col-md px-2 offset-8">
        <div class="row no-gutters mx-n1">
          <div class="col-md-10 px-1">
            <button
              class="btn btn-lg btn-block btn-success"
              @click="btnCheckoutOnClick"
            >
              <i class="fas fa-fw fa-cash-register"></i>Bayar
            </button>
          </div>
          <div class="col-md-2 px-1">
            <button
              class="btn btn-lg btn-block btn-outline-danger"
              @click="btnResetOnClick"
            >
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ModalCheckout
      :form="form"
      :error="error"
      :isHospitalTransaction="false"
      @paymentMethodOnClick="paymentMethodOnClick"
      @btnShortCutOnClick="btnShortCutOnClick"
      @inputCashOnKeyUp="inputCashOnKeyUp"
      @btnEWalletOnClick="btnEWalletOnClick"
      @modalCheckoutOnHide="modalCheckoutOnHide"
      @confirmCheckout="confirmCheckout"
    />

    <ModalPatient
      :isCashier="true"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
      @customSubmitedPatient="customSubmitedPatient"
    />

    <ModalDoctor
      @chosenDoctor="setDoctor"
      @submitedDoctor="setDoctor"
      @customSubmitedDoctor="customSubmitedDoctor"
    />

    <ModalPrescription :source="1" @setSelectedItem="setSelectedItem" />

    <!-- <div class="d-none">
      <Print :items="items" :data="data" setting="" />
    </div> -->
  </div>
</template>

<script>
import Print from "@/component/pharmacy/pharmacy-orders/Print.vue";
import MedicineCard from "@/component/pharmacy/cashier/MedicineCard.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import ModalPrescription from "@/component/general/ModalPrescription.vue";
import ModalCheckout from "@/component/general/ModalCheckout.vue";
import ModalDoctor from "@/component/general/ModalDoctor.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  components: {
    MedicineCard,
    ModalPatient,
    ModalCheckout,
    ModalDoctor,
    ModalPrescription,
    Print,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: "Pilih Pasien",
        doctor_name: "Pilih Dokter",
      },
      // Medicines
      medicines: [],
      medicineCategories: [],
      // Form
      form: {
        doctor_id: "",
        doctor_name: "",
        patient_id: "",
        patient_name: "",
        payment_method_id: 1,
        bank_id: "",
        card_number: "",
        card_type: "",
        edc_struct: "",
        e_wallet_id: "",
        surcharge: 0,
        total_amount: 0,
        tax_amount: 0,
        final_amount: 0,
        payment_amount: 0,
        change_amount: 0,
        notes: "",
        items: [],
        itemsUnavailable: [],
      },
      error: {
        bank_id: "",
        card_number: "",
        card_type: "",
        edc_struct: "",
      },
      // Filter
      filter: {
        medicine_category_id: "",
        search: "",
      },
      // Print Data
      items: [],
      data: {},
      // Others
      navActive: null,
      prescriptData: [],
      prescriptId: "",
      dataDisplay: false,
    };
  },

  methods: {
    async setSelectedItem(data, id) {
      // hide modal
      this.$bvModal.hide("modal-prescription");

      // set data form
      this.form.items = data.available;
      this.form.itemsUnavailable = data.unavailable;

      this.form.doctor_id = data.doctor_id;
      this.form.doctor_name = data.doctor_name;
      this.form.patient_id = data.patient_id;
      this.form.patient_name = data.patient_name;

      this.form.total_amount = 0;
      this.form.final_amount = 0;

      data.available.forEach((el) => {
        this.form.total_amount += el.subtotal;
        this.form.final_amount += el.subtotal;
      });

      // display
      this.display.patient_name = data.patient_name;
      this.display.doctor_name = data.doctor_name;

      // get data prescription
      this.getDataPrescription(id);
    },

    async getDataPrescription(id) {
      let prescript = await module.get(`prescriptions/${id}`);
      this.prescriptData = prescript;
      this.prescriptId = id;
    },

    setSelectedItemRoute(data, id) {
      data.available.forEach((el) => {
        this.cardMedicineOnClick(
          el.medicine_id,
          el.medicine_name,
          parseInt(el.amount),
          el.stock
        );
      });

      // // set data form
      // this.form.items = data.available;
      // this.form.itemsUnavailable = data.unavailable;

      // this.form.doctor_id = data.doctor_id;
      // this.form.doctor_name = data.doctor_name;
      // this.form.patient_id = data.patient_id;
      // this.form.patient_name = data.patient_name;

      // this.form.total_amount = 0;
      // this.form.final_amount = 0;

      // data.available.forEach((el) => {
      //   this.form.total_amount += el.subtotal;
      //   this.form.final_amount += el.subtotal;
      // });

      // // display
      // this.display.patient_name = data.patient_name;
      // this.display.doctor_name = data.doctor_name;

      // // get data prescription
      // let prescript = await module.get(`prescriptions/${id}`);
      // this.prescriptData = prescript;
      // this.prescriptId = id
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = `Pasien: ${value.name}`;
      this.$bvModal.hide("modal-patient");
    },

    customSubmitedPatient(value) {
      this.form.patient_name = value;
      this.display.patient_name = `Pasien: ${value}`;
      this.$bvModal.hide("modal-patient");
    },

    setDoctor(value) {
      this.form.doctor_id = value.id;
      this.display.doctor_name = `Dokter: ${value.name}`;
      this.$bvModal.hide("modal-doctor");
    },

    customSubmitedDoctor(value) {
      this.form.doctor_name = value;
      this.display.doctor_name = `Dokter: ${value}`;
      this.$bvModal.hide("modal-doctor");
    },

    resetForm() {
      this.form = validation.clearForm(this.form);
      this.form.items = [];
      this.form.payment_method_id = 1;
      this.form.total_amount = 0;
      this.form.final_amount = 0;
      this.form.change_amount = 0;
      this.form.tax_amount = 0;
      this.form.surcharge = 0;
      this.display.patient_name = "Pilih Pasien";
      this.display.doctor_name = "Pilih Dokter";
      this.data = {};
      this.items = [];
      this.error = validation.clearValidationError(this.error);
    },

    btnResetOnClick() {
      Swal.fire({
        title: "Konfirmasi",
        text: "Reset Order?",
        showCancelButton: true,
        icon: "question",
      }).then((result) => {
        if (result.value) {
          this.resetForm();
          window.data = [];
        }
      });
    },

    async getMedicine() {
      let slug = `?medicine_category_id=${this.filter.medicine_category_id}&search=${this.filter.search}`;
      this.medicines = await module.list("medicines/get-for-cashier", slug);
    },

    async getMedicineCategories() {
      this.medicineCategories = await module.list("medicine-categories");
    },

    navOnClick(index, id) {
      this.navActive = index;
      this.filter.medicine_category_id = id;
      this.getMedicine();
    },

    inputSearchOnKeyUp() {
      this.getMedicine();
    },

    btnAddQtyOnClick(medicineId, medicineName, medicineAmount, index) {
      let items = this.form.items,
        found = items[index],
        quantity = found.quantity;
      if (quantity >= found.stock) {
        Swal.fire({
          title: "Pemberitahuan",
          text: "Jumlah Melebihi Ketersediaan Stock!",
          icon: "warning",
          showConfirmButton: false,
          timer: 1800,
          timerProgressBar: true,
        });
      } else {
        found.quantity += 1;
        found.subtotal = found.amount * found.quantity;
        this.form.total_amount += parseInt(found.amount);
        this.form.final_amount += parseInt(found.amount);
      }
    },

    btnMinQtyOnClick(medicineId, medicineName, medicineAmount, index) {
      let items = this.form.items,
        found = items[index],
        quantity = found.quantity;
      if (quantity == 1) {
        quantity = 0;
        items.splice(index, 1);
      } else {
        found.quantity -= 1;
        found.subtotal = found.amount * found.quantity;
      }
      this.form.total_amount -= parseInt(found.amount);
      this.form.final_amount -= parseInt(found.amount);
    },

    cardMedicineOnClick(
      medicineId,
      medicineName,
      medicineAmount,
      medicineStock
    ) {
      if (medicineStock == 0) {
        Swal.fire("Peringatan", "Stok Habis", "warning");
      } else {
        let exist = false,
          quantity = 0,
          items = this.form.items;
        items.forEach((element) => {
          if (element.medicine_id == medicineId) {
            medicineId = element.medicine_id;
            quantity = element.quantity;
            exist = true;
          }
        });
        if (exist) {
          let found = items.findIndex((x) => x.medicine_id == medicineId);
          if (items[found].quantity >= items[found].stock) {
            Swal.fire({
              title: "Pemberitahuan",
              text: "Jumlah Melebihi Ketersediaan Stock!",
              icon: "warning",
              showConfirmButton: false,
              timer: 1800,
              timerProgressBar: true,
            });
          } else {
            items[found].quantity = quantity += 1;
            items[found].subtotal = parseInt(quantity * items[found].amount);
            this.form.total_amount += parseInt(medicineAmount);
            this.form.final_amount =
              this.form.total_amount + this.form.tax_amount;
          }
        } else {
          items.push({
            medicine_id: medicineId,
            medicine_name: medicineName,
            quantity: 1,
            amount: medicineAmount,
            subtotal: medicineAmount,
            stock: medicineStock,
            notes: "",
          });
          this.form.total_amount += parseInt(medicineAmount);
          this.form.final_amount =
            this.form.total_amount + this.form.tax_amount;
        }
      }
    },

    setMedicineItemData(
      medicineId,
      medicineName,
      medicineAmount,
      medicineStock,
      medicineQty
    ) {
      if (medicineStock == 0) {
        Swal.fire("Peringatan", "Stok Habis", "warning");
      } else {
        let exist = false,
          quantity = 0,
          items = this.form.items;
        items.forEach((element) => {
          if (element.medicine_id == medicineId) {
            medicineId = element.medicine_id;
            quantity = element.quantity;
            exist = true;
          }
        });
        if (exist) {
          let found = items.findIndex((x) => x.medicine_id == medicineId);
          if (items[found].quantity >= items[found].stock) {
            Swal.fire({
              title: "Pemberitahuan",
              text: "Jumlah Melebihi Ketersediaan Stock!",
              icon: "warning",
              showConfirmButton: false,
              timer: 1800,
              timerProgressBar: true,
            });
          } else {
            // items[found].quantity = quantity += 1;
            items[found].quantity = medicineQty;
            items[found].subtotal = parseInt(quantity * items[found].amount);
            this.form.total_amount += parseInt(medicineAmount * medicineQty);
            this.form.final_amount =
              this.form.total_amount + this.form.tax_amount;
          }
        } else {
          items.push({
            medicine_id: medicineId,
            medicine_name: medicineName,
            quantity: medicineQty,
            amount: medicineAmount,
            subtotal: medicineAmount,
            stock: medicineStock,
            notes: "",
          });
          this.form.total_amount += parseInt(medicineAmount * medicineQty);
          this.form.final_amount =
            this.form.total_amount + this.form.tax_amount;
        }
      }
    },

    modalCheckoutOnHide() {
      this.error = validation.clearValidationError(this.error);
      this.form.payment_amount = 0;
      this.form.change_amount = 0;
    },

    paymentMethodOnClick(id) {
      this.form.notes = "";
      this.form.payment_method_id = id;
    },

    btnCheckoutOnClick() {
      if (this.form.items.length == 0) {
        Swal.fire({
          title: "Peringatan",
          text: "Anda Belum Memilih Obat",
          icon: "warning",
        });
      } else {
        this.$bvModal.show("modal-checkout");
      }
    },

    btnShortCutOnClick(amount) {
      this.form.payment_amount = parseInt(amount).toLocaleString("id-ID");
      this.form.change_amount =
        parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
        this.form.final_amount;
    },

    btnEWalletOnClick(id) {
      this.form.e_wallet_id = id;
    },

    inputCashOnKeyUp() {
      this.form.change_amount =
        parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) -
        this.form.final_amount;
    },

    async confirmCheckout() {
      if (
        parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) <
          this.form.final_amount &&
        this.form.payment_method_id == 1
      ) {
        Swal.fire({
          title: "Peringatan",
          text: "Uang Tidak Cukup",
          icon: "warning",
        });
      } else {
        let response = await module.submit(
          this.form,
          "pharmacy-orders/checkout"
        );
        if (response.state == "error") {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          if (
            typeof this.prescriptId != "undefined" &&
            this.prescriptId != null &&
            this.prescriptId != ""
          ) {
            // update data redeem status prescription
            this.prescriptData.redeem_status = 1;
            this.prescriptData._method = "put";

            let res = await module.submit(
              this.prescriptData,
              `prescriptions/updateRedeem/${this.prescriptId}`
            );
          }

          Swal.fire({
            title: response.success.title,
            text: response.success.message,
            icon: "success",
          }).then((result) => {
            if (result.value) {
              this.$router.push(
                `/pharmacy/sales/detail/${response.success.order.order_id}`
              );
            }
          });
        }
      }
    },
  },

  // watch:{
  //   form : ()=>{
  //     this.form.items.splice(this.form.items.length)
  //     this.form.itemsUnavailable.splice(this.form.itemsUnavailable.length)

  //   }
  // },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "" },
      { title: "Tambah Penjualan" },
    ]);
    this.getMedicineCategories();
    this.getMedicine();

    // set data form prescriptions
    if (
      typeof window.data != "undefined" &&
      window.data != null &&
      window.data != []
    ) {
      let arr = [];
      arr = window.data;

      // push item into form selected item
      arr.available.forEach((el) => {
        this.setMedicineItemData(
          el.medicine_id,
          el.medicine_name,
          el.amount,
          el.stock,
          el.quantity
        );
      });

      // set patient data
      this.form.patient_id = arr.patient_id;
      this.form.patient_name = arr.patient_name
        ? arr.patient_name
        : "Pilih Pasien";
      this.display.patient_name = arr.patient_name
        ? arr.patient_name
        : "Pilih Pasien";

      // set doctor
      this.form.doctor_id = arr.doctor_id;
      this.form.doctor_name = arr.doctor_name
        ? arr.doctor_name
        : "Pilih Dokter";
      this.display.doctor_name = arr.doctor_name
        ? arr.doctor_name
        : "Pilih Dokter";

      // set prescription id
      this.getDataPrescription(window.id);

      // reset global data
      window.data = [];
    }
  },
};
</script>

<style scoped>
.nav-category {
  display: -webkit-box;
  white-space: nowrap !important;
  overflow-x: auto !important;
  scrollbar-width: none;
}

.nav-category li {
  display: inline-block !important;
  float: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #305b8f !important;
}

/* Handle on hover */
</style>
