<template>
  <div>

    <div
      class="card shadow-sm card-medicine"
      @click="cardOnClick(data.id, data.name, data.sales_price, data.stock)"
      style="border: none"
    >
      <div class="d-flex justify-content-center align-items-center image-container">
        <div style="height: 110px; width: 110px; position: relative">
          <img
            class="image"
            :src="data.photo"
            alt=""
          >
        </div>
      </div>
      <div
        class="px-3 pt-1"
        style="min-height: 54px"
      >
        <div
          class="card-text text-truncate"
          style="font-size: 14px"
        >{{ data.name }}</div>
        <div class="d-flex justify-content-between align-items-center mt-n1">
          <div
            class="text-primary text-hover-success"
            style="font-size: 14px"
          >Rp {{ parseInt(data.sales_price).toLocaleString('id-ID') }}</div>
          <div style="font-size: 13px">{{ data.stock }} Pcs</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {

  props: {
    data: Object
  },

  methods: {

    cardOnClick(id, name, amount, stock) {
      this.$emit('cardOnClick', id, name, amount, stock)
    }

  },

  mounted() {

  }

}
</script>

<style scoped>
.card-medicine:hover {
  box-shadow: 2px 2px 2px rgba(175, 175, 175, 0.527) !important;
  cursor: pointer;
}
</style>